<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <section class="gray pt-4" v-if="! loading">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="checkout-wrap">
                <div class="checkout-head">
                  <div class="text-center mb-3">
                    <h3>Add Agency</h3>
                  </div>
                  <ul>
                    <li>
                      <span><i class="fa fa-dot-circle-o"></i></span>Account
                      Information
                    </li>
                    <li :class="step == 1 ? 'active' : ''">
                      <span><i class="fa fa-dot-circle-o"></i></span>Agency
                      Details
                    </li>
                    <li :class="step == 2 ? 'active' : ''">
                      <span><i class="fa fa-dot-circle-o"></i></span>Documents
                    </li>
                    <li :class="step == 3 ? 'active' : ''">
                      <span><i class="fa fa-dot-circle-o"></i></span>Social
                    </li>
                  </ul>
                </div>
                <div class="checkout-body">
                  <h4 class="text-center text-muted" v-if="step == 1">
                    Agency Details
                  </h4>
                  <h4 class="text-center text-muted" v-if="step == 2">
                    Documents
                  </h4>
                  <h4 class="text-center text-muted" v-if="step == 3">
                    Social
                  </h4>
                  <form @submit.prevent="submitAgency">
                    <div class="row" v-show="step == 1">
                      <div class="col-md-12">
                        <alert type="danger" v-if="error_1">Fill out all the information</alert>
                        <div class="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            placeholder="Agency Name"
                            v-model="agency.name"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Logo</label>
                          <input
                            type="file"
                            placeholder="Agency Logo"
                            ref="logo"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            placeholder="Agency Email"
                            v-model="agency.email"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            placeholder="Agency Phone Number"
                            v-model="agency.phone_number"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Physical Address</label>
                          <input
                            type="text"
                            placeholder="Physical Address"
                            v-model="agency.address"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>About Agency</label>
                          <textarea
                            class="form-control"
                            required
                            v-model="agency.about"
                            placeholder="About"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12 text-end">
                        <a
                          href="#"
                          @click.prevent="validate(1)"
                          class="btn btn-primary"
                          >Next</a
                        >
                      </div>
                    </div>
                    <div class="row" v-show="step == 2">
                      <div class="col-md-12">
                        <alert type="danger" v-if="error_2">Fill out all the information</alert>
                        <div class="form-group">
                          <label>Registration Certificate</label>
                          <input
                            type="file"
                            placeholder="Registration Certificate"
                            ref="registration"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Approval Letter</label>
                          <input
                            type="file"
                            placeholder="Approval Letter"
                            ref="approval"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-12 text-end">
                        <a
                          href="#"
                          @click.prevent="step = 1"
                          class="btn btn-primary float-start"
                          >Previous</a
                        >
                        <a
                          href="#"
                          @click.prevent="validate(2)"
                          class="btn btn-primary"
                          >Next</a
                        >
                      </div>
                    </div>
                    <div class="row" v-show="step == 3">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label
                            >Website
                            <small class="text-muted">*optional</small></label
                          >
                          <input
                            type="text"
                            placeholder="Website"
                            v-model="agency.socials.website"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label
                            >Facebook
                            <small class="text-muted">*optional</small></label
                          >
                          <input
                            type="text"
                            placeholder="Facebook"
                            v-model="agency.socials.facebook"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label
                            >Twitter
                            <small class="text-muted">*optional</small></label
                          >
                          <input
                            type="text"
                            placeholder="Twitter"
                            v-model="agency.socials.twitter"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label
                            >Instagram
                            <small class="text-muted">*optional</small></label
                          >
                          <input
                            type="text"
                            placeholder="Instagram"
                            v-model="agency.socials.instagram"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label
                            >Youtube
                            <small class="text-muted">*optional</small></label
                          >
                          <input
                            type="text"
                            placeholder="Youtube"
                            v-model="agency.socials.youtube"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label
                            >Tiktok
                            <small class="text-muted">*optional</small></label
                          >
                          <input
                            type="text"
                            placeholder="Tiktok"
                            v-model="agency.socials.tiktok"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-12 text-end">
                        <div class="form-group">
                          <a
                            href="#"
                            @click.prevent="step = 2"
                            class="btn btn-primary float-start"
                            >Previous</a
                          >
                          <button class="btn btn-primary">
                            <i class="lni lni-plus me-2"></i>Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <loading v-if="loading" />

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    if (!this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({
        name: "login",
      }).href;
    }
  },

  data() {
    return {
      loading: false,
      step: 1,
      agency: {
        name: "",
        email: "",
        phone_number: "",
        address: "",
        about: "",
        socials: {
          website: "",
          facebook: "",
          twitter: "",
          instagram: "",
          youtube: "",
          tiktok: "",
        },
      },
      error_1: false,
      error_2: false
    };
  },

  methods: {
    validate(step) {
      if(step == 1) {
        if(
          this.agency.name.length < 1 || 
          this.agency.email.length < 1 || 
          this.agency.phone_number.length < 1 ||
          this.agency.address.length < 1 ||
          this.agency.about.length < 1 ||
          this.$refs.logo.files[0] == null
        ) {
          window.scroll(0, 0);
          return this.error_1 = true
        }

        this.step = 2
      }

      if(step == 2) {
        if(this.$refs.registration.files[0] == null || this.$refs.approval.files[0] == null) {
          window.scroll(0, 0);
          return this.error_2 = true
        }

        this.step = 3
      }
    },

    submitAgency() {
      this.loading = true;

      let data = new FormData();
      data.append("name", this.agency.name);
      data.append("logo", this.$refs.logo.files[0]);
      data.append("registration", this.$refs.registration.files[0]);
      data.append("approval", this.$refs.approval.files[0]);
      data.append("email", this.agency.email);
      data.append("phone_number", this.agency.phone_number);
      data.append("address", this.agency.address);
      data.append("about", this.agency.about);
      data.append("socials[website]", this.agency.socials.website);
      data.append("socials[facebook]", this.agency.socials.facebook);
      data.append("socials[twitter]", this.agency.socials.twitter);
      data.append("socials[instagram]", this.agency.socials.instagram);
      data.append("socials[youtube]", this.agency.socials.youtube);
      data.append("socials[tiktok]", this.agency.socials.tiktok);

      this.$axios.post("/api/v1/my-account/agencies", data).then((response) => {
        this.loading = false;
        this.$router.push({
          name: "page",
          params: { slug: response.data.agency.slug },
        });
      });
    },
  },

  watch: {
    step() {
      window.scroll(0, 0);
    },
  },
};
</script>
